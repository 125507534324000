



















import { Component, Prop, Vue } from 'vue-property-decorator'

import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'

import { FooterProps } from './Footer.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Footer>({
  name: 'Footer'
})
export class Footer extends Vue implements FooterProps {
  @Prop({ type: Object, required: false })
  public siteLogo?: FooterProps['siteLogo']

  @Inject(SiteServiceType, false)
  protected readonly siteService?: ISiteService

  public get hasSiteLogo (): boolean {
    return typeof this.siteLogo !== 'undefined'
  }

  /**
   * Determines address of the active site.
   */
  public get siteAddress (): string {
    return this.siteService?.getActiveSiteAddress() || '/'
  }

  public get siteName (): string {
    return this.siteService?.getActiveSite().name ?? ''
  }
}

export default Footer
