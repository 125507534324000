






import { Component, Prop, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import FooterSimple from '../../../../front/shared/organisms/Footer/FooterSimple.vue'
import { FooterProps } from '../../../../front/shared/organisms/Footer/Footer.contracts'

import { translateToFooterProps } from '../../Footer.helpers'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Simple>({
  name: 'Simple',
  components: { FooterSimple }
})
export class Simple extends Vue {
  @Prop({ type: Object, required: true })
  public readonly data!: AnyObject

  /**
   * Maps `data.content` to `FooterProps`.
   */
  public get toFooterProps (): FooterProps {
    return translateToFooterProps(this.data)
  }
}

export default Simple
